@font-face {
    font-family: "Pokemon GB";
    src: url("fonts/Pokemon GB.woff") format("woff");
    src: url("fonts/Pokemon GB.ttf") format("truetype");
}

@font-face {
    font-family: "Press Start 2P";
    src: url("fonts/PressStart2P-Regular.woff") format("woff");
    src: url("fonts/PressStart2P-Regular.ttf") format("truetype");
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.framed {
    position: relative;
    border-image: url("./images/frame-hd.png") 42 round;
    border-width: 21px;
    border-style: solid;
    padding: 1em;
    margin: 1em 0 0 0;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
}
.framed.no-hd {
    border-image: url("./images/frame.png") 7 round;
    border-width: 7px;
}
.framed.neutral {
    background: #f8f3f8;
    color: #181010;
}
.framed.neutral.exclude-border {
    background-clip: padding-box;
}
.framed.primary {
    background: #48a058;
    color: #f8f3f8;
    text-shadow: -1px -1px 0 #181010, 1px -1px 0 #181010, -1px 1px 0 #181010,
        1px 1px 0 #181010;
}
.framed.primary.exclude-border {
    background-clip: padding-box;
}
.framed.secondary {
    background: #f0b088;
    color: #f8f3f8;
    text-shadow: -1px -1px 0 #181010, 1px -1px 0 #181010, -1px 1px 0 #181010,
        1px 1px 0 #181010;
}
.framed.secondary.exclude-border {
    background-clip: padding-box;
}
.framed.danger {
    background: #b3181c;
    color: #f8f3f8;
    text-shadow: -1px -1px 0 #181010, 1px -1px 0 #181010, -1px 1px 0 #181010,
        1px 1px 0 #181010;
}
.framed.danger.exclude-border {
    background-clip: padding-box;
}

.stats {
    position: relative;
    border: 4px solid #181010;
    border-top: none;
    border-right: none;
    padding: 1em;
}
.stats::after {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    content: "";
    width: 2px;
    height: 2px;
    font-size: 2px;
    color: #181010;
    box-shadow: -4em -1em 0 #181010, -5em -1em 0 #181010, -4em 0 0 #181010,
        -5em 0 0 #181010, -3em 0 0 #181010, -2em 0 0 #181010, 1em 2em 0 #181010,
        1em 2em 0 #181010, 2em 2em 0 #181010, 2em 2em 0 #181010;
}
.stats h2 {
    background-color: #f8f3f8;
    margin-left: -1em;
    margin-top: -1em;
    padding: 1em 1em 1em 0;
}

a.button,
a.button:hover,
a.button:active {
    color: #181010;
    text-decoration: none;
}

button,
.button {
    position: relative;
    font-family: "Pokemon GB";
    font-size: 1em;
    background: none;
    border: none;
    padding: 0 0 0 1em;
    text-transform: uppercase;
    cursor: pointer;
}
button:hover::before,
.button:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: 2px;
    font-size: 2px;
    color: #181010;
    box-shadow: 1em 0em 0 #181010, 2em 0em 0 #181010, 1em 1em 0 #181010,
        2em 1em 0 #181010, 3em 1em 0 #181010, 1em 2em 0 #181010,
        2em 2em 0 #181010, 3em 2em 0 #181010, 4em 2em 0 #181010,
        1em 3em 0 #181010, 2em 3em 0 #181010, 3em 3em 0 #181010,
        4em 3em 0 #181010, 5em 3em 0 #181010, 1em 4em 0 #181010,
        2em 4em 0 #181010, 3em 4em 0 #181010, 4em 4em 0 #181010,
        1em 5em 0 #181010, 2em 5em 0 #181010, 3em 5em 0 #181010,
        1em 6em 0 #181010, 2em 6em 0 #181010;
}
button.pokemon,
.button.pokemon {
    text-indent: -1000em;
    width: 100%;
    background: url("./images/pkmn.png") 1em center no-repeat;
    background-size: contain;
}

ul.buttons {
    display: inline-flex;
    padding: 0;
    list-style: none;
    flex-direction: column;
}
ul.buttons li {
    padding: 0;
    margin: 0.5em;
}
ul.buttons.compact {
    flex-direction: row;
    flex-wrap: wrap;
}
ul.buttons.compact li {
    flex-basis: 45%;
}

.progress-bar-container {
    display: flex;
    align-items: center;
}
.progress-bar-container label {
    margin-right: 1em;
}
.progress-bar-container .progress-bar,
.progress-bar-container progress {
    position: relative;
    flex-grow: 1;
    height: 4px;
    border: 2px solid transparent;
    border-image-slice: 1;
    border-image-width: 2px;
    border-block: 0;
    border-image-outset: 2px 0 2px 0;
    border-image-repeat: stretch stretch;
    border-image-source: url("./images/progress-frame.png");
    background: none;
    background-clip: padding-box;
}
.progress-bar-container progress::-webkit-progress-bar {
    background: none;
}
.progress-bar-container progress::-webkit-progress-value,
.progress-bar-container progress.primary::-webkit-progress-value,
.progress-bar-container progress.primary::-moz-progress-bar {
    background: #48a058;
}
.progress-bar-container .progress-bar.p1::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 1%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p1.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="1"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p1::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p2::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 2%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p2.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="2"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p2::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p3::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 3%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p3.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="3"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p3::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p4::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 4%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p4.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="4"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p4::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p5::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 5%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p5.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="5"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p5::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p6::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 6%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p6.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="6"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p6::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p7::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 7%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p7.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="7"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p7::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p8::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 8%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p8.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="8"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p8::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p9::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 9%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p9.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="9"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p9::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p10::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 10%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p10.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="10"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p10::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p11::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 11%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p11.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="11"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p11::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p12::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 12%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p12.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="12"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p12::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p13::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 13%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p13.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="13"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p13::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p14::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 14%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p14.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="14"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p14::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p15::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 15%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p15.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="15"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p15::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p16::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 16%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p16.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="16"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p16::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p17::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 17%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p17.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="17"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p17::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p18::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 18%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p18.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="18"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p18::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p19::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 19%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p19.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="19"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p19::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p20::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #b3181c;
    width: 20%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p20.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="20"]::-moz-progress-bar {
    height: 4px;
    background: #b3181c;
}
.progress-bar-container progress.p20::-webkit-progress-value {
    background: #b3181c;
}
.progress-bar-container .progress-bar.p21::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 21%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p21.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="21"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p21::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p22::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 22%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p22.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="22"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p22::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p23::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 23%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p23.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="23"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p23::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p24::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 24%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p24.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="24"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p24::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p25::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 25%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p25.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="25"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p25::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p26::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 26%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p26.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="26"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p26::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p27::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 27%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p27.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="27"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p27::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p28::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 28%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p28.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="28"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p28::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p29::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 29%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p29.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="29"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p29::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p30::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 30%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p30.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="30"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p30::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p31::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 31%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p31.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="31"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p31::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p32::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 32%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p32.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="32"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p32::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p33::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 33%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p33.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="33"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p33::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p34::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 34%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p34.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="34"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p34::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p35::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 35%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p35.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="35"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p35::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p36::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 36%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p36.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="36"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p36::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p37::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 37%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p37.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="37"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p37::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p38::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 38%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p38.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="38"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p38::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p39::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 39%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p39.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="39"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p39::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p40::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 40%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p40.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="40"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p40::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p41::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 41%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p41.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="41"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p41::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p42::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 42%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p42.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="42"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p42::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p43::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 43%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p43.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="43"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p43::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p44::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 44%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p44.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="44"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p44::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p45::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 45%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p45.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="45"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p45::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p46::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 46%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p46.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="46"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p46::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p47::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 47%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p47.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="47"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p47::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p48::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 48%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p48.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="48"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p48::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p49::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 49%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p49.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="49"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p49::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p50::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #f0b088;
    width: 50%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p50.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="50"]::-moz-progress-bar {
    height: 4px;
    background: #f0b088;
}
.progress-bar-container progress.p50::-webkit-progress-value {
    background: #f0b088;
}
.progress-bar-container .progress-bar.p51::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 51%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p51.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="51"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p51::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p52::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 52%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p52.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="52"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p52::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p53::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 53%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p53.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="53"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p53::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p54::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 54%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p54.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="54"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p54::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p55::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 55%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p55.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="55"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p55::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p56::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 56%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p56.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="56"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p56::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p57::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 57%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p57.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="57"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p57::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p58::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 58%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p58.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="58"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p58::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p59::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 59%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p59.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="59"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p59::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p60::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 60%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p60.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="60"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p60::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p61::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 61%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p61.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="61"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p61::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p62::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 62%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p62.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="62"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p62::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p63::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 63%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p63.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="63"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p63::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p64::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 64%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p64.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="64"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p64::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p65::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 65%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p65.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="65"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p65::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p66::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 66%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p66.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="66"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p66::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p67::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 67%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p67.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="67"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p67::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p68::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 68%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p68.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="68"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p68::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p69::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 69%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p69.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="69"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p69::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p70::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 70%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p70.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="70"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p70::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p71::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 71%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p71.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="71"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p71::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p72::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 72%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p72.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="72"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p72::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p73::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 73%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p73.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="73"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p73::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p74::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 74%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p74.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="74"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p74::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p75::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 75%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p75.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="75"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p75::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p76::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 76%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p76.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="76"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p76::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p77::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 77%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p77.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="77"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p77::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p78::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 78%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p78.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="78"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p78::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p79::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 79%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p79.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="79"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p79::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p80::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 80%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p80.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="80"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p80::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p81::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 81%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p81.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="81"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p81::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p82::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 82%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p82.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="82"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p82::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p83::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 83%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p83.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="83"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p83::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p84::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 84%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p84.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="84"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p84::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p85::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 85%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p85.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="85"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p85::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p86::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 86%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p86.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="86"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p86::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p87::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 87%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p87.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="87"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p87::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p88::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 88%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p88.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="88"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p88::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p89::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 89%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p89.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="89"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p89::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p90::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 90%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p90.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="90"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p90::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p91::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 91%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p91.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="91"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p91::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p92::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 92%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p92.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="92"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p92::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p93::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 93%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p93.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="93"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p93::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p94::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 94%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p94.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="94"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p94::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p95::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 95%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p95.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="95"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p95::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p96::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 96%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p96.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="96"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p96::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p97::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 97%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p97.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="97"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p97::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p98::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 98%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p98.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="98"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p98::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p99::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 99%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p99.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="99"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p99::-webkit-progress-value {
    background: #48a058;
}
.progress-bar-container .progress-bar.p100::after {
    content: "";
    display: inline-block;
    position: absolute;
    background: #48a058;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
}
.progress-bar-container .progress-bar.p100.primary::after {
    background: #48a058;
}
.progress-bar-container progress[value="100"]::-moz-progress-bar {
    height: 4px;
    background: #48a058;
}
.progress-bar-container progress.p100::-webkit-progress-value {
    background: #48a058;
}

body {
    font-family: "Pokemon GB";
    font-smooth: never;
    -webkit-font-smoothing: none;
    min-width: 320px;
    background: #f8f3f8;
    color: #181010;
    image-rendering: pixelated;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

.wrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.options {
    display: flex;
    justify-content: space-around;
}

.opponent {
    text-align: center;
    padding: 1em;
    animation-name: floater;
    animation-duration: 6000ms;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}
.opponent img {
    width: 64px;
}

.message span {
    font-weight: bolder;
}

@media only screen and (max-width: 760px) {
    .framed {
        padding: 0.5em;
        margin: 0.5em 0 0 0;
    }
    .stats {
        padding: 0.5em;
    }
    .stats h2 {
        margin-left: -0.5em;
        margin-top: -0.5em;
        padding: 0.5em 0.5em 0.5em 0;
    }
    .opponent {
        padding: 0.5em;
    }
}
