.playerContainer {
    display: flex;
    justify-content: space-between;
}
.playerStats {
    border: 2px dashed black;
    height: 130px;
    margin-top: 20px;
    padding: 5px;
}

.playerNameLevel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.playerPokemonLevel {
    margin-left: auto;
}

.healthBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.healthbar {
}

.remainingHealth {
}

.playerSprite {
    height: 200px;
    width: 200px;
}
