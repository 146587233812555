.textBox {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.back {
    float: right;
}

.mainMenu {
    max-width: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mainMenu > button {
    margin: 10px;
}
