.gameContainer {
    max-width: 600px;
    margin: auto;
}

dialog {
    margin-top: 20%;
}

.newGameButton {
    margin-left: 25%;
}

.gameOverText {
    padding: 10px;
}

.healthBar {
    border: 2px solid black;
    background-color: green;
    width: 150px;
    height: 20px;
}

.healthBar75 {
    border: 2px solid black;
    width: 150px;
    height: 20px;
    background-image: linear-gradient(to right, green 75%, white 25%);
}

.healthBar50 {
    border: 2px solid black;
    width: 150px;
    height: 20px;
    background-image: linear-gradient(to right, green 50%, white 50%);
}

.healthBar25 {
    border: 2px solid black;
    width: 150px;
    height: 20px;
    background-image: linear-gradient(to left, white 75%, red 25%);
}

.healthBar0 {
    border: 2px solid black;
    width: 150px;
    height: 20px;
    background-color: white;
}
