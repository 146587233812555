.foeContainer {
    display: flex;
    justify-content: space-between;
}

.foeStats {
    border: 2px dashed black;
    height: 130px;
    width: 250px;
    margin-top: 20px;
    padding: 5px;
}

.foeNameLevel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.foeHealthBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.foePokemonLevel {
    padding: 0 20px 0 70px;
}

.foeSprite {
    height: 150px;
}
